import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../components/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component:  () => import(/* webpackChunkName: "siteBody" */ '../components/About.vue')
  },
  {
    path: '/careers',
    name: 'Careers',
    component:  () => import(/* webpackChunkName: "siteBody" */  '../components/Careers.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    component:  () => import(/* webpackChunkName: "siteBody" */ '../components/Contact.vue')
  },
  {
    path: '/investigation',
    name: 'Investigation',
    component:  () => import(/* webpackChunkName: "siteBody" */ '../components/Investigation.vue')
  },
  {
    path: '/security',
    name: 'Security',
    component:  () => import(/* webpackChunkName: "siteBody" */ '../components/Security.vue')
  },
  {
    path: '/admin',
    name: 'Admin',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin" */ '../components/time-clock/Admin.vue')
  },
  {
    path: '/clock',
    name: 'Clock',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "clock" */ '../components/time-clock/TimeClock.vue')
  },
  {
    path: '/time-clock',
    name: 'Clock',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "clock" */ '../components/time-clock/TimeClock.vue')
  },
  {
    path: '/help',
    name: 'Help',
    // route level code-splitting
    
    // which is lazy-loaded when the route is visited.         /components/time-clock/GeolocationHelp.vue
    component: () => import(/* webpackChunkName: "clock" */ '../components/time-clock/GeolocationHelp.vue')
  }
]

const router = new VueRouter({
  routes,
})

export default router
