<template>
    <div class="mx-3" style="margin-top: 8px">
        <b-container fluid>
            <b-row align-h="around">
                <b-col md="4" class="my-3">
                    <info-card v-bind="this.services" style="margin:auto"></info-card>
                </b-col>
                <b-col md="4" class="my-3">
                    <info-card v-bind="this.whyProof" style="margin:auto"></info-card>
                </b-col>
                <b-col md="4" class="my-3">
                    <info-card v-bind="this.ourMission" style="margin:auto"></info-card>
                </b-col>
            </b-row>
            
            <b-row class="my-3">
                <b-col md class="m-2">
                <b-img src="../assets/bodyworn-794111_1280.jpg" fluid style="min-width:200px; max-height:300px"></b-img>
                </b-col>
                <b-col md="7" class="m-2">
                    <!-- <img src="../assets/bodyworn-794111_1280.jpg" hspace="50" vspace="32" align="left" style="object-fit:contain; max-height:300px"> -->
                    <h3 class="tm-color-heading">Individualized focus, National presence</h3>
                    <p class="tm-color-body">
                        Proof Technologies provides an elite professional, reliable and cost-effective security force to clients nationwide.  
                        Our experienced provider network ensures that your armed security and investigative services needs can be met around the clock and across the United States.   
                        For a decade, Michigan-based Proof Technologies has earned its reputation for professionalism, safety and the ability to dispatch and manage an array of security solutions, 
                        from daily 24/7 retail security to emergency and disaster response teams. Dynamic and innovative, we offer a uniquely holistic approach to our clients and their customers. 
                    </p>
                </b-col>
            </b-row>
            </b-container>
            <div class="tm-bottom-seperator m-3"></div>
            <b-container fluid>
                <b-row>
                <b-col>
                    <h3 class="tm-color-heading">Proof Technologies</h3>
                    <p class="tm-color-body">
                            is committed to continuous improvement and a set of values we practice
                            daily in our work. We believe in legal compliance, fair treatment of our employees, rewards for advanced skill sets and absolute reliability.  
                            Our roster of clients includes banks, national and regional retailers, pharmacies and dispensaries, and private individuals and entrepreneurs. 
                            We tailor our services to fit your needs. 
                    </p>
                </b-col>
            </b-row>
        </b-container>
        
    </div>
</template>

<script>
import InfoCard from './InfoCard'

export default {
  name: "Home",
  components: {
      InfoCard
  },
  props: {
    msg: String,
  },
  data() {
    return { 
        message: "Home!",
        services: {
            title: "Security & Investigation",
            hint: "Our Services",
            body:   `Proof provides expert <b>armed and unarmed services</b> to multi-national corporations as well as private clients. Our <b>private investigation</b> work, with a specialization in surveillance and cyber-surveillance, can meet any investigation needs you may have.
            `
        },
        ourMission: {
            title: "Our Mission",
            hint: "More",
            body:   `The PROOF Mission Statement is to provide the best possible security service to all of our clients, while always maintaining safety, honesty, and integrity. The PROOF vision is to be a trusted partner of those who utilize our services.`
        },
        whyProof: {
            title: "The Right Choice",
            hint: "Learn Why",
            body:   `Proof Security personnel are qualified security guards who are trained in customer service, occupational health and safety, loss prevention, control room operation and emergency response. We can discuss your particular requirements and tailor skills accordingly. Servicing you 24/7, our trained and licensed staff are committed to delivering you quality security services.`
        },
    };
  },
  methods: {
      login() {
          this.$auth.loginWithRedirect();
      },
      logout() {
        this.$auth.logout({
            returnTo: window.location.origin
        });
      }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
