<template>
    <div class="p-2 shadow" style="max-width: 400px; cursor: pointer;" @click="handleCardClick" @mouseover="isHovering=true" @mouseleave="isHovering=false"> 
        <h3 class="tm-color-heading mt-3" style="text-align:center; vertical-align:bottom">{{title}}</h3>
        <div ref="svgTarget" class="svg-target" style="width: 100%">
            <svg width="100%" height="34px" viewBox="0 0 304 34" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <g id="Techno-Fern" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <line x1="2.5" y1="0.5" x2="302.5" y2="0.5" id="seperator" stroke="#42B4E6"></line>
                    <polyline class="path" id="grow-1" stroke="#42B4E6" points="93 0.852574829 93 13.6428475 87 13.6428475 87 29.9581541"></polyline>
                    <polyline class="path" id="grow-2" stroke="#42B4E6" points="112.5 0.852574829 112.5 19.2894906 118.980226 19.2894906 118.980226 25.0046578"></polyline>
                    <polyline class="path" id="grow-3" stroke="#42B4E6" points="136.058128 0.852574829 136.058128 10.5091911 128.5 15.4053644 136.058128 10.5091911 136.058128 29.9581541"></polyline>
                    <polyline class="path" id="grow-4" stroke="#42B4E6" points="156.308688 0.852574829 156.308688 9.83844637 165.236578 12.9286163 156.298146 9.83844637 147.359714 18.1786108 156.298146 9.83844637 156.308688 29.9581541"></polyline>
                    <polyline class="path" id="grow-5" stroke="#42B4E6" points="176.100069 0.852574829 176.100069 8.36386208 181.201156 8.36386208 181.201156 15.4053644 178.650612 15.4053644 178.650612 29.9581541"></polyline>
                    <line x1="101.158709" y1="0.879679013" x2="101.158709" y2="10.8459712" class="path" id="grow-8" stroke="#42B4E6"></line>
                    <line x1="120.947282" y1="0.852574829" x2="120.947282" y2="8.59599152" class="path" id="grow-8" stroke="#42B4E6"></line>
                    <line x1="190.820702" y1="0.852574829" x2="190.820702" y2="12.9286163" class="path" id="grow-7" stroke="#42B4E6"></line>
                    <polyline class="path" id="grow-6" stroke="#42B4E6" points="197.542645 0.852574829 197.542645 8.59599152 202.480452 8.59599152 202.480452 12.9286163"></polyline>
                    <polyline class="path" id="grow-1" stroke="#42B4E6" points="8 0.852574829 8 13.6428475 2 13.6428475 2 29.9581541"></polyline>
                    <polyline class="path" id="grow-2" stroke="#42B4E6" points="27.5 0.852574829 27.5 19.2894906 33.9802261 19.2894906 33.9802261 25.0046578"></polyline>
                    <polyline class="path" id="grow-3" stroke="#42B4E6" points="51.0581284 0.852574829 51.0581284 10.5091911 43.5 15.4053644 51.0581284 10.5091911 51.0581284 29.9581541"></polyline>
                    <polyline class="path" id="grow-4" stroke="#42B4E6" points="71.3086882 0.852574829 71.3086882 9.83844637 80.2365779 12.9286163 71.298146 9.83844637 62.3597142 18.1786108 71.298146 9.83844637 71.3086882 29.9581541"></polyline>
                    <line x1="16.1587087" y1="0.879679013" x2="16.1587087" y2="10.8459712" class="path" id="grow-8" stroke="#42B4E6"></line>
                    <line x1="35.9472818" y1="0.852574829" x2="35.9472818" y2="8.59599152" class="path" id="grow-8" stroke="#42B4E6"></line>
                    <polyline class="path" id="grow-2" stroke="#42B4E6" points="223.5 0.852574829 223.5 19.2894906 229.980226 19.2894906 229.980226 25.0046578"></polyline>
                    <polyline class="path" id="grow-3" stroke="#42B4E6" points="247.058128 0.852574829 247.058128 10.5091911 239.5 15.4053644 247.058128 10.5091911 247.058128 29.9581541"></polyline>
                    <polyline class="path" id="grow-4" stroke="#42B4E6" points="267.308688 0.852574829 267.308688 9.83844637 276.236578 12.9286163 267.298146 9.83844637 258.359714 18.1786108 267.298146 9.83844637 267.308688 29.9581541"></polyline>
                    <polyline class="path" id="grow-5" stroke="#42B4E6" points="287.100069 0.852574829 287.100069 8.36386208 292.201156 8.36386208 292.201156 15.4053644 289.650612 15.4053644 289.650612 29.9581541"></polyline>
                    <line x1="212.158709" y1="0.879679013" x2="212.158709" y2="10.8459712" class="path" id="grow-8" stroke="#42B4E6"></line>
                    <line x1="231.947282" y1="0.852574829" x2="231.947282" y2="8.59599152" class="path" id="grow-8" stroke="#42B4E6"></line>
                    <line x1="301.820702" y1="0.852574829" x2="301.820702" y2="12.9286163" class="path" id="grow-7" stroke="#42B4E6"></line>
                    <circle class="circle" id="leaf-1" fill="#42B4E6" cx="2" cy="31" r="2"></circle>
                    <circle class="circle" id="leaf-1" fill="#42B4E6" cx="16" cy="12" r="2"></circle>
                    <circle class="circle" id="leaf-1" fill="#42B4E6" cx="34" cy="26" r="2"></circle>
                    <circle class="circle" id="leaf-1" fill="#42B4E6" cx="36" cy="10" r="2"></circle>
                    <circle class="circle" id="leaf-1" fill="#42B4E6" cx="43" cy="16" r="2"></circle>
                    <circle class="circle" id="leaf-1" fill="#42B4E6" cx="51" cy="32" r="2"></circle>
                    <circle class="circle" id="leaf-1" fill="#42B4E6" cx="62" cy="19" r="2"></circle>
                    <circle class="circle" id="leaf-1" fill="#42B4E6" cx="71" cy="30" r="2"></circle>
                    <circle class="circle" id="leaf-1" fill="#42B4E6" cx="81" cy="14" r="2"></circle>
                    <circle class="circle" id="leaf-1" fill="#42B4E6" cx="87" cy="31" r="2"></circle>
                    <circle class="circle" id="leaf-1" fill="#42B4E6" cx="101" cy="12" r="2"></circle>
                    <circle class="circle" id="leaf-1" fill="#42B4E6" cx="119" cy="26" r="2"></circle>
                    <circle class="circle" id="leaf-1" fill="#42B4E6" cx="121" cy="10" r="2"></circle>
                    <circle class="circle" id="leaf-1" fill="#42B4E6" cx="128" cy="16" r="2"></circle>
                    <circle class="circle" id="leaf-1" fill="#42B4E6" cx="136" cy="32" r="2"></circle>
                    <circle class="circle" id="leaf-1" fill="#42B4E6" cx="147" cy="19" r="2"></circle>
                    <circle class="circle" id="leaf-1" fill="#42B4E6" cx="166" cy="14" r="2"></circle>
                    <circle class="circle" id="leaf-1" fill="#42B4E6" cx="156" cy="30" r="2"></circle>
                    <circle class="circle" id="leaf-1" fill="#42B4E6" cx="179" cy="30" r="2"></circle>
                    <circle class="circle" id="leaf-1" fill="#42B4E6" cx="191" cy="14" r="2"></circle>
                    <circle class="circle" id="leaf-1" fill="#42B4E6" cx="203" cy="14" r="2"></circle>
                    <circle class="circle" id="leaf-1" fill="#42B4E6" cx="212" cy="12" r="2"></circle>
                    <circle class="circle" id="leaf-1" fill="#42B4E6" cx="230" cy="26" r="2"></circle>
                    <circle class="circle" id="leaf-1" fill="#42B4E6" cx="232" cy="10" r="2"></circle>
                    <circle class="circle" id="leaf-1" fill="#42B4E6" cx="247" cy="32" r="2"></circle>
                    <circle class="circle" id="leaf-1" fill="#42B4E6" cx="239" cy="16" r="2"></circle>
                    <circle class="circle" id="leaf-1" fill="#42B4E6" cx="258" cy="19" r="2"></circle>
                    <circle class="circle" id="leaf-1" fill="#42B4E6" cx="277" cy="14" r="2"></circle>
                    <circle class="circle" id="leaf-1" fill="#42B4E6" cx="267" cy="30" r="2"></circle>
                    <circle class="circle" id="leaf-1" fill="#42B4E6" cx="290" cy="30" r="2"></circle>
                    <circle class="circle" id="leaf-1" fill="#42B4E6" cx="302" cy="14" r="2"></circle>
                </g>
            </svg>
        </div>
        <b-collapse v-model="show">
            <div class="my-4" >
                <span v-html="body"></span>
            </div>
        </b-collapse>
        <div class="m-3" style="text-align:right; margin:auto">
            <label style="text-align:right; margin:auto">{{displayHint}}</label>
        </div>
    </div>
</template>

<script>
import anime from 'animejs/lib/anime.es.js'

export default {
    name: 'InfoCard',
    props: {
        msg: String,
        body: String,
        title: String,
        hint: String,
    },
    data() {
        return {
            email: '',
            isHovering: false,
            show: false,
            timeline: null,
        }
    },
    watch: {
        isHovering: function(newValue, oldValue) {
            if (newValue == oldValue) {
                return;
            }

            if (newValue) {
                console.log(`playing animation`)
                if (!this.show) {
                    this.timeline.play();
                }
            } else {
                console.log(`restarting...`)
                if (!this.show) {

                    this.timeline.restart();
                    this.timeline.pause();
                }
            }
        }
    },
    methods: {
        handleCardClick() {
            this.show = !this.show
        }
    },
    computed: {
        displayHint: function() {
            if (this.show) {
                return "Hide"
            } else {
                return this.hint
            }
        }
    },
    mounted: function() {
        console.log(`Animation Targets: ${this.$refs.svgTarget}`)

        this.timeline = anime.timeline({loop: false, delay: 0, autoplay: false});
        this.timeline.add({
            targets: this.$refs.svgTarget.querySelectorAll(".path"),
            strokeDashoffset: [anime.setDashoffset, 0],
            easing: 'easeInOutSine',
            duration: function() { return anime.random(50, 200)},
            delay: anime.stagger(10),
            direction: 'normal',
            loop: false,
            autoplay: false
        })  
        .add({
            targets: this.$refs.svgTarget.querySelectorAll('.circle'),
            r: [0, 2],
            loop: false, 
            duration: function() { return anime.random(40, 1500)},
            delay: anime.stagger(20),
            direction: 'normal',
            autoplay: false
        });
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.circle-target {
    scale: 0;
}
.svg-target {
    scale: 1;
}
.b-card {
    max-width: 40rem;
}
</style>