import Vue from 'vue'
import App from './App.vue'
import { BootstrapVue } from 'bootstrap-vue'
import router from './router'
// import { domain, clientId, audience } from "./auth0-config.json";
// import { Auth0Plugin } from './auth-plugin.js';
// /////////////////////////////////////////
// /////////////////////////////////////////
// console.log("Adding Auth0 plugin...")
// Vue.use(Auth0Plugin, {
//     domain,
//     clientId,
//     audience,
//     onRedirectCallback: appState => {
//         console.log(`In authorization callback: ${Vue.router}`)
//         router.push(
//         appState && appState.targetUrl
//             ? appState.targetUrl
//             : window.location.pathname
//         );
//     }
// });
// console.log("Added Auth0 plugin...")
// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)

// Website Themes and Colors
import './assets/theme.css';

Vue.config.productionTip = false
Vue.use(BootstrapVue)


new Vue({
  el: '#app',
  router,
  render: h => h(App)
})//.$mount('#app')
